import { createPopper } from '@popperjs/core';
import Choices from 'choices.js';

export default function initFormSelects() {
    const element = document.querySelectorAll('.js-cf-form .js-select-input');
    const hints = document.querySelectorAll('.js-cf-form .js-form-hint');
    const textareas = document.querySelectorAll('textarea');

    if (textareas.length > 0) {
        textareas.forEach(function (textarea) {
            if (window.innerWidth < 768) {
                textarea.style.height = textarea.scrollHeight + 'px';
                textarea.style.overflowY = 'hidden';
            }

            textarea.addEventListener('input', function () {
                if (window.innerWidth < 768) {
                    this.style.height = 'auto';
                    this.style.height = this.scrollHeight + 'px';
                }
            });
        });
    }

    if (element.length > 0) {
        element.forEach(function (node) {
            let choices = new Choices(node, {
                placeholder: true,
                searchEnabled: false,
                loadingText: '',
                itemSelectText: '',
                allowHTML: true,
                classNames: {
                    containerOuter: 'choices form-select',
                },
            });
        });
    }

    if (hints) {
        hints.forEach(item => {
            const button = item.querySelector('.form-input__hintButton');
            const tooltip = item.querySelector('.form-input__hintTooltip');
            document.body.appendChild(tooltip);

            const tooltipInstance = createPopper(button, tooltip, {
                placement: 'top-end',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [10, 10],
                        },
                    },
                    {
                        name: 'arrow',
                        options: {},
                    },
                    {
                        name: 'preventOverflow',
                        options: {
                            boundary: 'viewport', // Keep the tooltip within the viewport
                        },
                    },
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: ['top', 'bottom'],
                        },
                    },
                ],
            });

            function toggleTooltip() {
                const isVisible = tooltip.hasAttribute('data-show');

                if (isVisible) {
                    hideTooltip();
                } else {
                    tooltip.setAttribute('data-show', '');
                    tooltip.style.display = 'block';
                    tooltipInstance.update();

                    document.addEventListener('click', handleOutsideClick);
                }
            }

            function hideTooltip() {
                tooltip.removeAttribute('data-show');
                tooltip.style.display = 'none';
                document.removeEventListener('click', handleOutsideClick);
            }

            function handleOutsideClick(event) {
                if (
                    !tooltip.contains(event.target) &&
                    !button.contains(event.target)
                ) {
                    hideTooltip();
                }
            }

            button.addEventListener('click', toggleTooltip);
        });
    }
}
